@import "../src/colors.scss";

.engage-container {
	position: fixed;
	width: 0px;
	height: 0px;
	bottom: 0px;
	right: 0px;
	z-index: 2147483647;

	.engage-chat-icon {
		height: 45px;
	}

	.engage-close-icon {
		height: 35px;
	}
}

.engage-container img {
	height: initial;
}

.engage-message-frame>iframe {
	width: 100%;
	height: 100%;
}

.engage-message-frame {
	box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 40px !important;
	width: 450px;
	min-height: 250px;
	max-height: 700px;
	height: calc(100% - 120px);
	overflow-y: hidden;
	position: fixed;
	bottom: 80px;
	right: 20px;
	border-radius: 10px;
}

.engage-hidden {
	display: none;
}

@media only screen and (max-width: 600px) {
	.engage-message-frame {
		bottom: 0;
		width: 100%;
		right: 0;
		top: 0;
		height: 100%;
		max-height: unset;
		z-index: 2;
	}
}

.engage-button {
	display: none;
	position: fixed;
	bottom: 20px;
	right: 20px;
	width: 70px;
	height: 70px;
	cursor: pointer;
	transform-origin: center center;
	backface-visibility: hidden;
	-webkit-font-smoothing: antialiased;
	border-radius: 37px 37px 15px 37px;
	overflow: hidden;
	background-color: $header-bg;
	box-shadow: 0 5px 4px 0 rgba(0, 0, 0, 0.26);
	align-items: center;
	justify-content: center;
	/* border: 3px solid #000; */
}

.engage-button:hover {
	filter: brightness(0.9);
}

.engage-close-icon {
	display: none;
	position: absolute;
}